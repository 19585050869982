<script>
import PageSubmenu from '@/components/PageSubmenu.vue'

export default {
  components: { PageSubmenu },
  computed: {
    domain () {
      return this.$store.getters.domain
    }
  },
  data () {
    return {
      links: [
        {
          label: 'Utilisateurs',
          path: '/app/users/user',
          roles: ['admin', 'director', 'community-manager']
        },
        {
          label: 'Observateurs',
          path: '/app/users/operator',
          roles: ['admin', 'director', 'community-manager']
        },
        {
          label: 'Administrateurs',
          path: '/app/users/director',
          roles: ['admin', 'community-manager']
        },
        {
          label: 'Account Managers',
          path: '/app/users/account-manager',
          roles: ['admin', 'community-manager']
        },
        {
          label: 'Community Managers',
          path: '/app/users/community-manager',
          roles: ['admin']
        }
      ]
    }
  },
  created () {

  }
}
</script>

<template>
  <div>
    <PageSubmenu :links="links" />
  </div>
</template>
