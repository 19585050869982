<script>
export default {
  props: ['user', 'userIndex', 'isLast', 'isFirst'],
  data () {
    return {
      isValid: true
    }
  },
  methods: {
    checkValidEmails () {
      this.isValid = false
      const regex = /^[\w-.]+@([\w-]+\.)+[\wa-]{2,4}(;[\w-.]+@([\w-]+\.)+[\w-]{2,4})*$/
      this.isValid = regex.test(this.user.email)
      if (!this.isValid) {
        this.$emit('error', true)
      }
    }
  }
}
</script>

<template>
  <div v-if="user" class="grid grid-cols-4 gap-5">
    <div v-if="!(isLast && isFirst)" class="col-span-4 pt-2">
      <span class="font-bold text-xl">Utilisateur {{ userIndex + 1}}</span>
      <t-button class="ml-3 inline" variant="error" v-if="!isFirst" @click.prevent="$emit('removeUser')"> - </t-button>
    </div>
    <div class="col-span-4">
      <t-input-group
        label="Email"
        :variant="{ 'danger': !isValid }"
        :feedback="isValid ? '': 'Veuillez saisir une adresse email valide'"
      >
        <t-input
          type="email"
          :variant="{ 'danger': !isValid }"
          :value="user.email"
          @input="$event => $emit('update', { label: 'email', value: $event })"
          @blur="checkValidEmails"
        />
      </t-input-group>
    </div>

    <t-input-group
      label="Nom"
      class="col-span-4 md:col-span-2"
    >
      <t-input
        type="text"
        :value="user.lastname"
        @input="$event => $emit('update', { label: 'lastname', value: $event })"
      />
    </t-input-group>

    <t-input-group
      label="Prénom"
      class="col-span-4 md:col-span-2"
    >
      <t-input
        type="text"
        :value="user.firstname"
        @input="$event => $emit('update', { label: 'firstname', value: $event })"
      />
    </t-input-group>

    <t-button variant="success" v-if="isLast" @click.prevent="$emit('addUser')"> + </t-button>
  </div>
</template>
