<template>
  <div v-if="userData">
    <div class="border-b p-3 rounded-t bg-gray-100">
      <h3 class="text-lg text-gray-900">
        {{ userData.uuid ? 'Modification' : 'Création' }} d'un utilisateur
      </h3>
    </div>

    <div class="space-y-6 bg-white sm:p-6">
      <t-input-group :class="`col-span-${ hasRoleSelect? '2' : '4' }`" label="Avatar">
        <b-upload @input="uploadAvatar" accept="image/png, image/jpeg">
          <avatar :user="user"/>
        </b-upload>
      </t-input-group>

      <div class="grid grid-cols-2 gap-3">
        <t-input-group label="Nom">
          <t-input
            v-model="userData.lastname"
            placeholder="Nom"
          />
        </t-input-group>

        <t-input-group label="Prénom">
          <t-input
            v-model="userData.firstname"
            placeholder="Prénom"
          />
        </t-input-group>

        <t-input-group label="Adresse e-mail">
          <t-input
            type="email"
            v-model="userData.email"
            required
            placeholder="Adresse e-mail"
            disabled
          />
        </t-input-group>

        <t-input-group label="Organisation">
          <t-input
            v-model="userData.organization"
            required
            placeholder="Organisation"
          />
        </t-input-group>

        <t-input-group
          label="Rôle"
          v-if="hasRoleSelect"
        >
          <t-select
            placeholder="Select a name"
            v-model="userData.accreditations[0]"
            :options="translatedRoles"
            @input="updateRole"
          />
        </t-input-group>

        <t-input-group
          label="Groupes (facultatif)"
          v-if="formattedGroups.length > 0"
        >
          <t-rich-select
            multiple
            :close-on-select="false"
            :options="formattedGroups"
            placeholder="Sélectionner des groupes"
            v-model="groupsSelected"
            value-attribute="value"
            text-attribute="text"
          ></t-rich-select>
        </t-input-group>
        <t-input-group
          label="Domaine(s)"
          v-if="formattedDomains.length > 0 && isAdmin"
        >
          <t-rich-select
            multiple
            :close-on-select="false"
            :options="formattedDomains"
            placeholder="Ajouter ou supprimer l'utilisateur à des domaines"
            v-model="domainsSelected"
            value-attribute="value"
            text-attribute="text"
          ></t-rich-select>
        </t-input-group>

      </div>
      <div
        class="flex space-x-16"
        v-if="userData.accreditations && (userData.accreditations[0] === 'director' || userData.accreditations[0] === 'operator')"
      >
        <t-input-group
          class="col-span-3"
          label="Peut répondre aux conversations"
        >
          <t-toggle v-model="userData.can_use_chat" />
        </t-input-group>
        <t-input-group
          v-if="roles.includes('admin') || roles.includes('community-manager')"
          class="col-span-3"
          label="Est référent"
        >
          <t-toggle v-model="userData.is_assignee" />
        </t-input-group>
      </div>
    </div>

    <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-between border-t border-t-gray-300">
      <div class="flex gap-3 items-center">
        <!-- <label for="active">Actif (Validé)</label>
        <t-toggle
          class="ml"
          v-model="userData.is_verified"
          :value="1"
          :uncheckedValue="0"
        /> -->
      </div>
      <div class="flex gap-3">
        <t-button
          v-if="userData.uuid"
          class="m-0"
          @click.prevent="notifyUser"
          variant="secondary"
        >
          Notifier
        </t-button>
        <t-button
          class="m-0"
          variant="error"
          @click.prevent="deleteUser"
        >
          Supprimer
        </t-button>
        <t-button @click.prevent="updateUser">
          Mettre à jour
        </t-button>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar'

export default {
  name: 'app-user',
  components: { Avatar },
  data () {
    return {
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      basicAvatar: '/logo.png',
      allRoles: ['user', 'operator', 'director'],
      formattedGroups: [],
      groupsSelected: [],
      rolesTraduction: {
        'user': 'Utilisateur',
        'community-manager': 'Community Manager',
        'operator': 'Observateur',
        'manager': 'Opérateur',
        'director': 'Admin'
      },
      hasRoleSelect: false,
      userData: null,
      userUniqueKey: null,
      formattedDomains: [],
      domainsSelected: []
    }
  },
  props: [
    'role',
    'user',
    'userKey',
    'groups',
    'domains'
  ],
  computed: {
    ...mapGetters([
      'roles'
    ]),
    isAdmin () {
      return this.roles.includes('admin')
    },
    translatedRoles () {
      const filteredRoles = {}
      for (let role of this.allRoles) {
        filteredRoles[role] = this.rolesTraduction[role]
      }
      return filteredRoles
    }
  },
  beforeMount: async function () {
    this.userUniqueKey = this.userKey || this.$route.params.uuid
    await http.get(`/user/${this.userUniqueKey}`).then(({ data }) => {
      this.userData = data
      this.setIfHasRoleSelect()

      if (this.userData.groups) {
        this.groupsSelected = this.userData.groups.map(group => group.uuid)
      }

      if (this.isAdmin && this.userData.domains) {
        this.domainsSelected = this.userData.domains.map(domain => domain.uuid)
      }
    }).catch(err => {
      console.log(err)
      this.$notify({
        group: 'maviepro-error',
        text: 'Impossible de récupérer l\'utilisateur.'
      })
      this.$parent.$modal.hide('user-form')
    })

    this.formattedGroups = this.groups.map(group => ({
      value: group.uuid,
      text: group.label
    }))

    this.formattedDomains = this.domains.map(domain => ({
      value: domain.uuid,
      text: domain.label
    }))
  },

  mounted () {
    if (['admin', 'community-manager'].some(role => this.roles.includes(role))) {
      this.allRoles.push('director')
    }
  },
  methods: {
    setIfHasRoleSelect () {
      const isAdmin = ['admin', 'community-manager'].some(role => this.roles.includes(role))
      if ((this.userData.accreditations[0] !== 'director' && !isAdmin) || isAdmin) {
        this.hasRoleSelect = true
      }
    },
    updateRole (value) {
      http.put(`/user/${this.userUniqueKey}/role`, { role: value }).then(({ data }) => {
        this.$emit('refresh')
        this.$notify({
          group: 'maviepro-success',
          text: 'Rôle de l\'utilisateur mis à jour.'
        })
      }).catch(err => {
        console.log(err)
        this.$notify({
          group: 'maviepro-error',
          text: 'Erreur lors de la mis à jour du rôle.'
        })
      })
    },
    updateUser () {
      const {
        firstname,
        lastname,
        organization,
        can_use_chat, //eslint-disable-line
        is_verified,  //eslint-disable-line
        is_assignee, //eslint-disable-line
      } = this.userData

      const payload = {
        firstname,
        lastname,
        organization,
        can_use_chat,
        is_verified,
        is_assignee,
        groups: this.groupsSelected
      }

      if (this.isAdmin) {
        payload.domains = this.domainsSelected
      }

      http
        .put(`/user/${this.userData.uuid}`, payload)
        .then((res) => {
          this.$notify({
            group: 'maviepro-success',
            text: 'Utilisateur mis à jour.'
          })
          this.$emit('refresh')
          this.$parent.$modal.hide('user-form')
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'maviepro-error',
            text: 'Erreur lors de la mise à jour de l\'utilisateur.'
          })
        })
    },
    deleteUser () {
      this.$dialog.confirm({
        title: 'Supprimer cet utilisateur ?',
        text: 'Cette action ne peut pas être annulée.',
        icon: 'warning',
        cancelButtonText: 'Annuler',
        okButtonText: 'Supprimer'
      }).then((result) => {
        if (result.isOk && result.hideReason === 'ok') {
          http.delete(`/user/${this.userUniqueKey}`).then(res => {
            this.$notify({
              group: 'maviepro-success',
              text: 'Utilisateur supprimé.'
            })
            this.$emit('refresh')
            this.$parent.$modal.hide('user-form')
          }).catch(err => {
            console.log(err)
            this.$notify({
              group: 'maviepro-error',
              text: 'Problème lors de la suppression, veuillez contacter un administrateur.'
            })
          })
        }
      })
    },
    uploadAvatar (file) {
      const formData = new FormData()
      formData.append('avatar', file)
      http.post(`/user/${this.userData.uuid}/avatar`, formData).then(({ data }) => {
        this.user = data
        // this.domain.logo = data.logo
        this.$notify({
          group: 'maviepro-success',
          text: 'Avatar mis à jour.'
        })
      }).catch(err => {
        console.log(err)
        this.$notify({
          group: 'maviepro-error',
          text: 'Erreur lors de la mise à jour de l\'avatar.'
        })
      })
    },
    notifyUser () {
      this.$buefy.dialog.confirm({
        title: 'Notification',
        message: `Êtes vous sur de vouloir renvoyer une notification à cet utilisateur ?`,
        cancelText: 'Annuler',
        confirmText: 'Notifier',
        type: 'is-danger',
        onConfirm: () => {
          http.get(`/user/${this.userData.uuid}/notify`).then(res => {
            this.$notify({
              group: 'maviepro-success',
              text: 'Utilisateur notifié.'
            })
            this.$parent.$modal.hide('user-form')
          }).catch(err => {
            console.log(err)
            this.$notify({
              group: 'maviepro-error',
              text: 'Erreur lors de l\'envoi de la notification.'
            })
          })
        }
      })
    }
  }
}
</script>
